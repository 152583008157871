import { AppDispatch, RootState, store } from '../index';
import { FetchState } from '../fetchState';
import {
  InvitationDto,
  InvitationDtoStateEnum,
  InvitationDtoTypeEnum,
  ReferenceDto,
  SupplierEmployeeDto,
} from '@qcs/safety-client';
import {
  getList,
  getListSort,
  ListFilter,
  listSlice,
  ListType,
} from '../listHelper';
import { CancelToken } from 'axios';
import { invitationsApi } from '../../utils/api';
import { DateFromTo } from '../../models/common';
import { getDateForApi } from '../../utils/date';

export interface InvitationListFilter extends ListFilter {
  name?: string;
  supplier?: ReferenceDto;
  supplierEmployee?: SupplierEmployeeDto;
  employee?: ReferenceDto;
  validFrom?: DateFromTo;
  validTo?: DateFromTo;
  state?: InvitationDtoStateEnum[];
  invitationTypes?: InvitationDtoTypeEnum[];
}

export type InvitationListState = ListType<InvitationDto, InvitationListFilter>;

const initialState: InvitationListState = {
  state: FetchState.None,
  data: undefined,
  query: {
    sortBy: 'valid_from',
    sortDesc: true,
    //Changed in identity after login.
    state: Object.values(InvitationDtoStateEnum).filter(
      (x) =>
        x !== InvitationDtoStateEnum.Deleted &&
        x !== InvitationDtoStateEnum.Archived
    ),
  },
};

export const invitationListSlice = listSlice({
  name: 'invitationList',
  initialState,
});

export const selectInvitationList = (state: RootState) => state.invitationList;
export const invitationListActions = invitationListSlice.actions;

export const getInvitationList =
  (companyId: string, cancelToken: CancelToken) => (dispatch: AppDispatch) => {
    const storeState = store.getState();
    const data = selectInvitationList(storeState);

    const handleGetData = (size: number) => {
      return invitationsApi.queryInvitations(
        companyId,
        data.query.name || undefined,
        data.query.supplier?.id,
        undefined, //responsiblePerson
        data.query.supplierEmployee?.id,
        data.query.employee ? [data.query.employee.id] : undefined,
        getDateForApi(data.query.validFrom?.from),
        getDateForApi(data.query.validFrom?.to),
        getDateForApi(data.query.validTo?.from),
        getDateForApi(data.query.validTo?.to),
        data.query.state,
        undefined, //trainingState
        data.query.invitationTypes,
        data.query.page,
        size,
        // default sort is creation date descending
        [...getListSort(data) || [], 'created,desc'],
        { cancelToken: cancelToken as any }
      );
    };

    getList({
      dispatch,
      cancelToken,
      state: data.state,
      actions: invitationListActions,
      getData: handleGetData,
    });
  };
